import { ComponentProps } from 'react'

import { SiteLocale } from '../graphql/datoSchema.generated'
import { Page } from '../graphql/page'
import { SiteSettings } from '../graphql/siteSettings'
import { getPageTitle } from '../pages/[[...slugs]]'
import { usePageContext } from '../utils/PageContext'
import resolveUrlForRecord from '../utils/resolveUrlForRecord'
import Seo from './Seo'

interface Props {
  page: Page
  seoSettings: SiteSettings['globalSeo']
  canonical?: ComponentProps<typeof Seo>['canonical']
  alternates?: ComponentProps<typeof Seo>['alternates']
}

const PageSeo = ({ page, seoSettings, canonical, alternates }: Props) => {
  const { siteConfig } = usePageContext()
  const fallbackDescription = seoSettings?.fallbackSeo?.description || undefined
  const fallbackTwitterCard = seoSettings?.fallbackSeo?.twitterCard || undefined
  const fallbackSharingImage = seoSettings?.fallbackSeo?.image
    ? {
        src: seoSettings?.fallbackSeo?.image.url,
        alt: seoSettings?.fallbackSeo?.image.alt || '',
      }
    : undefined

  return (
    <Seo
      title={getPageTitle(page, seoSettings)}
      description={page.seo?.description || fallbackDescription}
      image={
        page.seo?.image
          ? {
              src: page.seo?.image.url,
              alt: page.seo?.image.alt || undefined,
            }
          : fallbackSharingImage
      }
      twitterCard={page.seo?.twitterCard || fallbackTwitterCard}
      canonical={canonical}
      alternates={alternates}
      paths={(page.allSlugs || []).reduce((obj, { locale, value }) => {
        if (locale && value) {
          // eslint-disable-next-line no-param-reassign
          obj[locale] = resolveUrlForRecord(
            siteConfig,
            {
              __typename: 'PageRecord',
              slug: value,
            },
            locale,
          )
        }
        return obj
      }, {} as Record<SiteLocale, string>)}
    />
  )
}

export default PageSeo
